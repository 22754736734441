
body {
    color: white;
      background-color: #3d3d3d;
  }
  
  .Welcome-logo {
      text-align: justify;
      align-items: center;
      justify-content: center;
      height: 40vmin;
      pointer-events: none;
      padding: 3%
  }

  @media (prefers-reduced-motion: no-preference) {
      .Welcome-logo {
          animation: Welcome-logo-spin infinite 2.5s linear;
      }
  }
  
  @keyframes Welcome-logo-spin {
      0% {
          transform: rotate(0deg);
      }
      50% {
          transform: rotate(60deg);
      }
  }
  